import { message } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useState, useEffect, useCallback } from 'react';

import request from '@/utils/request';
import { userState } from '@/stores/userStore';

function useManager () {
  const [user, setUser] = useRecoilState(userState);

  useEffect(() => {
    if (user) {
      request.call('Manager.PageManagers', {Query: JSON.stringify({ID: user.Info.ID})})
        .then((res) => {
          const json = res.data;
          const info = json.Data?.Data[0];
          if (json.Error || !info) {
            delete localStorage[STORE_KEY];
            location.href = '/login';
          } else {
            setUser((preState) => ({...preState, Info: info}));
          }
        });
    }
  }, [user?.Info?.ID || 0]);
}

function useLogout () {
  const [loading, setLoading] = useState(false);
  const onLogout = useCallback((evt) => {
    evt.preventDefault();

    setLoading(true);
    request.call('Manager.Logout', {})
      .then((res) => {
        const json = res.data;
        if (json.Error) {
          message.error(json.Error.detail);
        } else {
          setTimeout(() => {
            delete localStorage[STORE_KEY];
            location.href = '/login';
          });
        }
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  return [loading, onLogout];
}

function useCollapse () {
  const [collapsed, setCollapsed] = useState(localStorage.ui_collapsed === '1');
  const toggle = useCallback(() => {
    let v = !collapsed;
    setCollapsed(v);
    localStorage.ui_collapsed = Number(v);
  }, [collapsed, setCollapsed]);

  return [collapsed, toggle];
}

function useMenu () {
  const [menu, setMenu] = useState('user');

  return [menu, setMenu];
}

let menuData = null;

function useMenuData () {
  const user = useRecoilValue(userState);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user) {
      if (!menuData) {
        import('@/menus.json').then((res) => res.default).then((json) => {
          setData(json);
          menuData = json;
        });
      } else {
        setData(menuData);
      }
    }
  }, [user]);

  return data;
}

export { useManager, useLogout, useCollapse, useMenu, useMenuData };
